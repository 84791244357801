<template>
    <path fill="currentColor" d="m400 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm-272 48c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm160-96c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm155.3 104.5-101.9 20.4c-11.4-17.4-31-28.9-53.4-28.9-35.4 0-64 28.6-64 64 0 11.7 3.4 22.5 8.9 32h110.3c3.5-6.1 5.7-12.9 7.2-20l102.4-20.5c13-2.6 21.4-15.2 18.8-28.2s-15.4-21.6-28.3-18.8zm-267.3-120.5c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112-144c-159.1 0-288 128.9-288 288 0 52.8 14.3 102.3 39.1 144.8 5.6 9.6 16.3 15.2 27.4 15.2h443c11.1 0 21.8-5.6 27.4-15.2 24.8-42.5 39.1-92 39.1-144.8 0-159.1-128.9-288-288-288zm212.3 400h-424.6c-18.1-34.4-27.7-72.9-27.7-112 0-132.3 107.7-240 240-240s240 107.7 240 240c0 39.1-9.6 77.6-27.7 112z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 576, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
